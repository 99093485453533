<template>
  <MobileHeader :headerNavItems="headerNavItems" v-if="isMobile" />
  <DesktopHeader :headerNavItems="headerNavItems" v-else />
</template>

<script>
import MobileHeader from './HeaderMobile.vue';
import DesktopHeader from './HeaderDesktop.vue';

export default {
  name: 'Header',
  components: {
    DesktopHeader,
    MobileHeader,
  },
  data() {
    return {
      headerNavItems: [
        {
          name: 'Locate a Charger',
          linkTo: 'locate-charger',
          nestedLinks: [],
        },
        {
          name: 'For Drivers',
          linkTo: null,
          nestedLinks: [
            {
              name: 'Getting Started',
              description: 'Learn the basics of electric vehicles charging',
              icon: 'getting-started',
              linkTo: 'getting-started',
              isExternal: false,
            },
            {
              name: 'Charging Your EV',
              description: 'Get step-by-step instructions on how to charge',
              icon: 'charging-your-ev',
              linkTo: 'charging-your-ev',
              isExternal: false,
            },
            {
              name: 'What to Expect',
              description: 'Learn what to expect when you charge with Electrify America',
              icon: 'what-to-expect',
              linkTo: 'what-to-expect',
              isExternal: false,
            },
            {
              name: 'Pricing and Payments',
              description: 'Learn about pricing information and payment options',
              icon: 'pricing',
              linkTo: 'pricing',
              isExternal: false,
            },
            {
              name: 'Premium Offers',
              description: 'Get complimentary charging or other benefits with your electric vehicle',
              icon: 'premium-offers',
              linkTo: 'premium-offers',
              isExternal: false,
            },
            {
              name: 'Sign Up for Updates',
              description: 'Stay up-to-date on Electrify America',
              icon: 'signup',
              linkTo: 'sign-up',
              isExternal: false,
            },
            {
              name: 'Get the App',
              description: 'Find a public charging station, and more',
              icon: 'mobile-app',
              linkTo: 'mobile-app',
              isExternal: false,
            },
            {
              name: 'Plug&Charge',
              description: 'Discover the simplicity and convenience of Plug&Charge',
              icon: 'plug-and-charge',
              linkTo: 'plug-and-charge',
              isExternal: false,
            },
          ],
        },
        {
          name: 'For Businesses',
          linkTo: null,
          nestedLinks: [
            {
              name: 'Business Solutions',
              description: 'Compare tailored electric vehicle charging solutions based on your business needs',
              icon: 'business-solutions',
              linkTo: 'business-solutions',
              isExternal: false,
            },
            {
              name: 'Host a Charging Station at Your Business',
              description: 'Work with us to potentially host an Electrify America charging station',
              icon: 'realestate',
              linkTo: 'real-estate',
              isExternal: false,
            },
          ],
        },
        {
          name: 'About Us',
          linkTo: null,
          nestedLinks: [
            {
              name: 'About Electrify America',
              description: 'Learn about what drives us',
              icon: 'about-us',
              linkTo: 'about-us',
              isExternal: false,
            },
            {
              name: 'Our Values',
              description: 'Discover our commitment to the environment, communities, and people',
              icon: 'our-values',
              linkTo: 'our-values',
              isExternal: false,
            },
            {
              name: 'Our Plan',
              description:
                'Explore our strategic vision for expanding ZEV infrastructure, access, and education programs',
              icon: 'our-plan',
              linkTo: 'our-plan',
              isExternal: false,
            },
            {
              name: 'Renewable Energy',
              description: 'Learn more about our commitment to renewable energy and sustainability',
              icon: 'renewable-energy',
              linkTo: 'renewable-energy',
              isExternal: false,
            },
            {
              name: 'Our Team',
              description: 'Get to know our people',
              icon: 'our-team',
              linkTo: 'our-team',
              isExternal: false,
            },
            {
              name: 'Careers',
              description: 'Be a part of the electric mobility revolution',
              icon: 'careers',
              linkTo: 'careers',
              isExternal: false,
            },
            {
              name: 'Newsroom',
              description: 'Stay up to date with company news and updates',
              icon: 'newsroom',
              linkTo: 'https://media.electrifyamerica.com/',
              isExternal: true,
            },
          ],
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(768);
    },
  },
  watch: {
    $resize() {},
  },
};
</script>
